/* Set base styles for HTML elements, such as headings, paragraphs, lists, etc. */

body {
    background-color: var(--color-white);
}

input[type="button"],
button,
.btn {
    display: block;
    width: 100%;
    padding: 3vw;
    border-radius: 2vw;
    text-align: center;
    margin: 3vw auto;

    &:hover:active {
        filter: brightness(90%);
    }

    &.btn-primary {
        background-color: var(--color-red);
        color: var(--color-white);
    }

    &.btn-secondary {
        background-color: var(--color-red-muted);
        color: var(--color-text-red-muted);
    }

    &.btn-just-text {
        color: var(--color-red);
    }

    &:disabled {
        background-color: var(--color-red-muted);
        color: var(--color-text-red-muted);
        font-weight: 400;
    }
}

input[type="text"],
input[type="password"] {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--color-border-input);
    padding: 5.3vw;
    padding-right: 10vw;
    border-radius: 2.7vw;
    margin-top: 2.7vw;
    margin-bottom: 2.7vw;

    
    &::placeholder {
        color: var(--color-text-light-gray);
    }

    &:focus {
        outline-color: var(--color-red);
    }

    &:read-only {
        outline-color: var(--color-disabled-border);
        background-color: var(--color-disabled-background);
        border-color: var(--color-disabled-border);
    }

    &.invalid {
        border: 1px solid var(--color-red);
    }

    +.show-password-icon-wrapper {
        line-height: var(--height-input-field);
        right: 3vw;
        position: absolute;
        vertical-align: middle;

        .icon-show-password {
            font-size: 4vw;
        }

        .icon-password-visibility {
            width: 5vw;
            margin-right: 6px;
        }

        .opaque {
            opacity: 0.38;
        }
    }

    +.unverified-icon-wrapper {
        line-height: var(--height-input-field);
        right: 3vw;
        position: absolute;
        vertical-align: middle;
        color: var(--color-red);
    }

    +.verified-icon-wrapper {
        line-height: var(--height-input-field);
        right: 3vw;
        position: absolute;
        vertical-align: middle;
        color: var(--color-green);
    }
}

.link {
    color: var(--color-red-muted);
    text-decoration: none;
}

.full-page-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
}

.back-btn {
    font-size: 8vw !important;
    position: fixed;
    top: 5vw;
    left: 5vw;
    color: var(--color-white);
}

@media (orientation: landscape) {
    input[type="button"],
button,
.btn {
    padding: 1vw;
}
}