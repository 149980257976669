/* Define global typographic styles, 
such as font families, sizes, and weights. */

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/assets/font/HelveticaNeue.ttf') format('truetype');
}

body {
    font-family: 'Helvetica Neue' // zeta
        , 'OpenSans'
        , Droid Sans // for Android
        , "Microsoft YaHei", "微软雅黑" // for wins
        , STXihei, "华文细黑" //for ios
        , sans-serif; // for Linux
    font-weight: 400;
    color: var(--color-text-default);
    height: 100%;
    font-size: get-vw(18px);
}

.text-white {
    color: var(--color-white) !important;
}

.text-black {
    color: var(--color-black);
}

.text-red {
    color: var(--color-red);
}

.text-red-muted {
    color: var(--color-red-muted);
}

.text-light-gray {
    color: var(--color-text-light-gray);
}

.text-blue {
    color: var(--color-blue);
}

.text {
    &.header {
        font-size: get-vw(22px); 
        font-weight: 700;
        color: var(--color-black)
    }

    &.header-small {
        @extend .text-default;
        font-weight: 700;
        color: var(--color-black)
    }
}

.text-default {
    font-size: get-vw(18px);
}

.text-small {
    font-size: get-vw(16px); 
}

.text-smaller {
    font-size: get-vw(14px);
}

.text-very-small {
    font-size: get-vw(12px); 
}

.text-extra-small {
    font-size: get-vw(10px);
}

.text-tiny {
    font-size: get-vw(8px);
}

.text-center {
    text-align: center;
}

.text-bold {
    font-weight: 700;
}

input[type="text"],
input[type="password"] {
    @extend .text-default;
    color: var(--color-text-input);

    &::placeholder {
        @extend .text-small;
    }
}

.material-symbols-outlined {
    font-size: 6vw !important;
    vertical-align: middle;
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 16
}

.text-ellipsis {
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
}

.text-align-right {
    text-align: right;
}

.btn-primary {
    @extend .text-default;
    font-weight: 600;
}

@media (orientation: landscape) {
    .text {
        &.header {
            font-size: get-vw(16px); 
            font-weight: 700;
            color: var(--color-black)
        }
    
        &.header-small {
            @extend .text-default;
            font-weight: 700;
            color: var(--color-black)
        }
    }

    .text-default {
        font-size: get-vw(14px);
    }
    
    .text-small {
        font-size: get-vw(12px); 
    }
    
    .text-smaller {
        font-size: get-vw(10px);
    }
    
    .text-very-small {
        font-size: get-vw(8px); 
    }
    
    .text-extra-small {
        font-size: get-vw(6px);
    }
    
    .text-tiny {
        font-size: get-vw(4px);
    } 
}