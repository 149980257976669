/* Store all your global variables, 
such as colors, font sizes, breakpoints, 
and other reusable values. */



:root {
  --color-white: #fff;
  --color-border-footer-gray: #BEBEBE;
  --color-border-list-gray: #E1E1E1;
  --color-blue: #417EC4;
  --color-green: #6DA96B;
  --color-gray: #646464;
  --color-light-gray: #E6E6E6;
  --color-red: #FF0000;
  --color-red-muted: #E38484;
  --color-text-gray-muted: #D0D5E2;
  --color-black: #343434;
  --color-dark-blue-pastel: #545F71;
  --color-border-panel-black: #0000001F;
  --color-text-red-muted: #F8C0C0;
  --color-text-default: #898989;
  --color-text-light-gray: #B6B6B6;
  --color-text-input: #383838;
  --color-border-input: #8A8A8A;
  --color-line-gray: #D3d3d3;
  --color-progress-bar: #E4E4E4;
  --color-progress-bar-done: #EC7B7B;
  --color-img-verification-code: #F1F1F1;
  --color-img-verification-code-border: #CCCCCC;
  --color-otp-channel-disabled: #FEFEFF;
  --color-otp-channel-disabled-border: #A6AAB2;
  --color-disabled-border: #DDDDDD;
  --color-disabled-background: #EAEAEA;
  --color-cancel-button: #B35353;
  --chat-conversation-message-color: #EAEAEA;
  --color-profile-input-disabled: #B6B6B6;
  --color-featured-streams-background: #EAEAEA;
  --color-announcements-background: #E5E5E5;
  --color-livestream-black-background: #000;
  --color-chat-quote-border: #545F71;

  --height-input-field: 23.5vw;
  --dynamic-vh: 0;

  $z-index: -2;
  @function nextIndex() {
    $z-index: $z-index + 1;
    @return ($z-index + 0);
  }

  // z-indices for better checking of overlapping
  // the closer the variable from this comment, the deeper it is in the z-index
  // the farther it is, the more it's at front
  @mixin z-indices {
    --z-index-to-back: #{nextIndex()};
    --z-index-neutral: #{nextIndex()};
    
    --z-index-slick-dots: #{nextIndex()};
    --z-index-i-frame: #{nextIndex()};
    --z-index-live-search-bar: #{nextIndex()};
    --z-index-chat-message-image: #{nextIndex()};
    --z-index-chat-announcements: #{nextIndex()};
    --z-index-chat-members-list: #{nextIndex()};
    --z-index-chat-input: #{nextIndex()};
    --z-index-video-menu: #{nextIndex()};
    --z-index-banned-display: #{nextIndex()};
    --z-index-action-button: #{nextIndex()};
    --z-index-back-button: #{nextIndex()};
    --z-index-backdrop: #{nextIndex()};
    --z-index-puzzle-captcha: #{nextIndex()};
    --z-index-modal-backdrop: #{nextIndex()};
    --z-index-modal: #{nextIndex()};
    --z-index-preloader-backdrop: #{nextIndex()};
    --z-index-preloader-img: #{nextIndex()};
  }

  @include z-indices();
}